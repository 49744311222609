<template>
  <LoginLayout>
    <div class="login-item">
      <h1 class="login-headline">確認コード</h1>
      <p class="description"><span class="text-note">メールアドレスに送信された確認コードを入力してください</span></p>
      <div class="login-container">
        <div>
          <div class="form">
            <div class="form-row">
              <div class="form-content">
                <div class="form-password">
                  <input
                    v-trim
                    v-model="form.code"
                    :class="{ 'form-control': true, 'is-error': errors.code }"
                    type="tel"
                    v-number
                    v-maxlength
                    maxlength="5"
                    placeholder="確認コードを入力"
                    name="code"
                  />
                </div>
              </div>
            </div>
            <div class="form-row">
              <a
                class="link text-align-center d-block"
                href="javascript:void(0)"
                :class="{ 'btn-disabled': isBtnCodeDisabled || isBlockAuthen }"
                @click="onResendCode"
              >
                確認コードを再送する
              </a>
            </div>
          </div>
          <ActionButton class="btn btn-w100 btn-blue" :handle-submit="handleAuthMail" button-text="登録する" />
        </div>
      </div>
    </div>
  </LoginLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
import password from '@/mixins/plugin/password';
import error from '@/mixins/plugin/error';
import { RouterConstants } from '@/constants/router';
import LoginLayout from '@/components/LoginLayout.vue';

export default {
  name: 'PasswordCode',
  data() {
    return {
      form: {},
      timerId: null,
      isBtnCodeDisabled: false,
    };
  },
  props: {
    copyright: String,
  },
  components: {
    LoginLayout,
  },
  computed: {
    ...mapGetters({
      token: 'auth/token',
      passwordExpiresAt: 'auth/passwordExpiresAt',
      isPasswordExpired: 'auth/isPasswordExpired',
      isBlockAuthen: 'auth/isBlockAuthen',
      userRole: 'auth/userRole',
      infor: 'auth/infor',
    })
  },
  validations() {
    const form = {
      code: { required, maxLength: maxLength(5) },
    }
    return { form };
  },
  mixins: [error, password],
  methods: {
    async onResendCode() {
      await this.$store.dispatch("auth/resendCode");
    },
    handleAuthMail: async function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'setCode');
      } else {
        const result = await this.$store.dispatch("auth/verifyCode", {
          code: this.form?.code
        });
        if (result) this.checkAuthorization();
        else this.checkVerifyCode();
      }
    },
    async checkAuthorization() {
      if (!this.passwordExpiresAt) {
        this.$router.push({ name: 'PasswordDefault' });
      } else if (this.isPasswordExpired) {
        this.$router.push({ name: 'PasswordReset' });
      } else {
        await this.$store.dispatch("auth/getUserInfor");
        if (this.userRole) {
          if (this.$permission.isGmoOrOffice()) {
            this.$router.push({ name: RouterConstants.DEFAULT_ROUTER_OF_MASTER_OR_CLIENT });
          }
          if (this.$permission.isStoreOrGroup()) {
            this.$router.push({ name: RouterConstants.DEFAULT_ROUTER_OF_SHOP_OR_GROUP });
          }
        }
      }
    },
    async checkVerifyCode() {
      let result = await this.$store.dispatch('auth/checkVerifyCode');
      this.isBtnCodeDisabled = !!result;
    },
  },
  created: function() {
    const loading = this.$loading.show();
    this.$parent.transitionName = '';
    Promise.all([
      this.$store.dispatch("auth/resendCode"),
    ]).finally(() => {
      this.$loading.clear(loading);
    })
  },
};
</script>

