<template>
  <div class="login-set">
    <div class="row g-0">
      <div class="col-auto login-col">
        <div class="login-item">
          <p class="login-logo"><img class="img-fluid" :src="logoSrc" :alt="logoAlt" /></p>
        </div>
      </div>
      <div class="col-auto login-col">
        <transition :name="transitionName">
          <slot></slot>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginLayout',
  data: function() {
    return {
      transitionName: '',
      logoAlt: process.env.VUE_APP_APP_NAME,
      logoSrc: process.env.VUE_APP_LOGO_IMAGE,
    };
  },
  watch: {
    $route: function(to, from) {
      if (from.name === null || to.name === 'PasswordReset') {
        this.transitionName = '';
      } else {
        this.transitionName = 'loginGo';
      }
    },
  },
}
</script>

